<template>
  <div>
    <!-- <textarea>{{JSON.stringify(series)}}</textarea> -->
          <apexchart type="line" height="350" :options="config.chartOptions" :series="series"></apexchart>
  </div>

</template>

<script>
import VueApexCharts from "vue-apexcharts";

import moment from 'moment-timezone'

export default {
  name: 'EmployeeGraph1',
  props: ['series'],
  components: {
    apexchart: VueApexCharts,
  },
  data(){
    return {

      config: {
        "series": [
          {
            "name": "Logins",
            "data": [

            ]
          },
          {
            "name": "Usage",
            "data": [
    
            ]
          }
        ],
        "chartOptions": {
          noData: {
            text: 'No Data Found',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: 'grey',
              fontSize: '14px',
              // fontFamily: undefined
            }
          },
          "chart": {
            "height": 350,
            "width": "100%",
            "type": "line",
            "zoom": {
              "enabled": false
            }
          },
          "dataLabels": {
            "enabled": false
          },
          "stroke": {
            "curve": "straight"
          },
          "title": {
            "text": "Employee Trends",
            "align": "left"
          },
          "grid": {
            "row": {
              "colors": [
                "#f3f3f3",
                "transparent"
              ],
              "opacity": 0.5
            }
          },
          "xaxis": {
            /*"categories": [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep"
            ],*/
            type: 'category',
            labels: {
              formatter: function (value) {
                return value;
              }
            }
          }
        }
      }

    }

  },

  watch: {
    series(val){
      console.log(val)
    }
  }

}
</script>