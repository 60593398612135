<template>
  <div>
    <!-- <textarea ref="placeholder">{{JSON.stringify(config.series)}}</textarea> -->
    <!-- <button @click="json">update</button> -->

    <apexchart type="bar" height="500" :options="config.chartOptions" :series="config.series"></apexchart>
  </div>

</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: 'EventGraph1',
  props: ['graph'],
  components: {
    apexchart: VueApexCharts,
  },
  created(){
    this.config.series = this.graph.data
    this.config.chartOptions.xaxis.categories = this.graph.categories
  },
  methods: {
    json(){
      let obj = JSON.parse(this.$refs.placeholder.value)
      this.config.series =  obj
    }
  },
  data(){
    return {

      config : {
        series: [
          {
            name: 'a',
            data: []
          }, {
            name: 'b',
            data: []
          }
        ],
        chartOptions: {
          chart: {
            type: 'bar',
                height: 430
          },
          plotOptions: {
            bar: {
              horizontal: true,
                  dataLabels: {
                position: 'top',
              },
            }
          },
          dataLabels: {
            enabled: true,
                offsetX: -6,
                style: {
              fontSize: '12px',
                  colors: ['#fff']
            },

            formatter: function (val, opt) {
              return val;
            }

          },
          stroke: {
            show: true,
                width: 1,
                colors: ['#fff']
          },
          tooltip: {
            shared: true,
                intersect: false
          },
          xaxis: {
            "categories": [

            ]
          },
        },
      }

    }
  }
}
</script>