<template>
    <div class="vx-col  w-full mb-base">
      <!-- TOOLTIP -->
      <vx-tooltip color="primary"
                  text="The table provides a summary of the user engagement on the various table topics in the café."
                  position="top">

        <vx-card title="Café Rooms">
          <div slot="no-body" class="mt-4">

            <vs-table :data="series" class="table-dark-inverted">
              <template slot="thead">
                <vs-th>Name</vs-th>
                <vs-th>Date</vs-th>
                <vs-th># Users</vs-th>
                <vs-th>Avg. Time(M)</vs-th>
                <vs-th>Rating</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].name">
                    <span>{{ data[indextr].name }}</span>
                  </vs-td>
                  <vs-td :data="data[indextr].from_date">
                    <span>{{ moment(formatDate(data[indextr].from_date)).format('DD/MM/YYYY') }}</span>
                  </vs-td>
                  <vs-td :data="data[indextr].attendance">
                    <span>{{ data[indextr].attendance }}</span>
                  </vs-td>
                  <vs-td :data="data[indextr].avg_time">
                    <span>{{ getAvgTime(data[indextr].avg_time, data[indextr].attendance) }}</span>
                  </vs-td>
                  <vs-td :data="data[indextr].avg_rating">
                    <span>{{ data[indextr].avg_rating? Number(data[indextr].avg_rating).toFixed(1): '-' }}</span>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>

          </div>

        </vx-card>
      </vx-tooltip>

    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

import moment from 'moment-timezone'
import Vue from 'vue'

Vue.prototype.moment = moment

export default {
  name: 'OtherGraph1',
  props: ['series'],
  components: {
    apexchart: VueApexCharts,
  },
  data(){
    return {

    }

  },
  methods:{
    formatDate(d) {
       return moment(d).format('YYYY-MM-DD HH:mm:ss')
    },
    getAvgTime(avg_time, attendance){
      if(attendance){
        return (avg_time/attendance).toFixed()
      }else return avg_time
    }
  }

}
</script>