<template>
  <div>
    <!-- <textarea>{{JSON.stringify(series)}}</textarea> -->
    <!--     &#128528;

          &#128515;
          &#x1F642;
          &#128528;
          &#128577;
            &#128546;
            &#128544;
   -->
    <!--   <div class="mood-emoji-container">
        
      </div>
   -->

    <apexchart type="line" height="350" :options="config.chartOptions" :series="config.series"></apexchart>

  </div>

</template>

<script>
import VueApexCharts from "vue-apexcharts";

import moment from 'moment-timezone'

export default {
  name: 'EmployeeGraph1',
  props: ['graph'],
  components: {
    apexchart: VueApexCharts,
  },
  created(){
    this.config.series = this.graph
  },
  data(){
    return {
      // mood_level: 2,
      config: {
        "series": [
          {
            "name": "Views",
            "data": [

            ]
          },
          {
            "name": "Likes",
            "data": [

            ]
          },
          {
            "name": "Dislikes",
            "data": [

            ]
          },
          {
            "name": "Favs",
            "data": [

            ]
          }
        ],
        "chartOptions": {
          noData: {
            text: 'No Data Found',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: 'grey',
              fontSize: '14px',
              // fontFamily: undefined
            }
          },
          "chart": {
            "height": 350,
            "width": "100%",
            "type": "line",
            "zoom": {
              "enabled": false
            }
          },
          "dataLabels": {
            "enabled": false
          },

          "title": {
            "text": "Video trends",
            "align": "left"
          },

          "xaxis": {
            type: "datetime",
            "categories": [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep"
            ]
          }
        }
      }

    }

  },

  watch: {
    series(val){
      console.log(val)
    }
  }

}
</script>