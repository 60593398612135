<template>
  <div>
    <apexchart type="bar" height="500" :options="config.chartOptions" :series="config.series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: 'ContentGraph1',
  props: ['data'],
  components: {
    apexchart: VueApexCharts,
  },
  created(){
    this.config.series = [
      {
        "name": "Watched For (Mins)",
        "data": this.data.data
      }
    ]

    this.config.chartOptions.xaxis.categories = this.data.categories
  },
  data() {
    return {
      config: {
        "series": [
          {
            "data": [
              100,
            ]
          }
        ],
        "chartOptions": {
          "chart": {
            "type": "bar",
            "height": 350
          },
          "plotOptions": {
            "bar": {
              "borderRadius": 4,
              "horizontal": true
            }
          },
          "dataLabels": {
            "enabled": false
          },
          "xaxis": {
            "categories": [

            ]
          },
          "title": {
            "text": "Most Engaging Videos"
          }
        }
      }
    }
  }
}
</script>