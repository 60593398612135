<template>
  <div>

    <vs-row>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total Events" position="top">
          <statistics-card-line icon="ActivityIcon" :statistic="data.total_events" statisticTitle="Total Events" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="How much of total employees participated in Events" position="top">
          <statistics-card-line icon="UsersIcon" :statistic="data.event_engagement_percent" statisticTitle="Event Participation Rate (%)" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Overall Approval rating of Events" position="top">
          <statistics-card-line icon="ThumbsUpIcon" :statistic="data.event_rating_percent" statisticTitle="Event Ratings (%)" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Average Event length" position="top">
          <statistics-card-line icon="WatchIcon" :statistic="data.event_average_length" statisticTitle="Average Event Length (Hrs)" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <!-- <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total Cafe Tables" position="top">
          <statistics-card-line icon="BoxIcon" :statistic="data.total_cafe_tables" statisticTitle="Café Tables" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Café table participation Rate" position="top">
          <statistics-card-line icon="CoffeeIcon" :statistic="data.cafe_table_participation_rate" statisticTitle="Café Interactions Rate (%)" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Average Stay in Cafe Tables" position="top">
          <statistics-card-line icon="CalendarIcon" :statistic="data.cafe_table_average_stay" statisticTitle="Café Average Stay" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total number of chat interactions with an iSabel Confidant" position="top">
          <statistics-card-line icon="MessageCircleIcon" :statistic="data.cafe_table_average_chats" statisticTitle="Café Average Chats" type="area"></statistics-card-line>
        </vx-tooltip>
      </div> -->


    </vs-row>

  </div>
</template>

<script>
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";

export default {
  name: 'EventCards',
  props: [
      'data'
  ],
  data(){
    return {

    }
  },
  components: {
    StatisticsCardLine,
  },
}
</script>