<template>
  <div>

    <vs-row>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total Number of Media on the platform" position="top">
          <statistics-card-line icon="SendIcon" :statistic="data.video_count" statisticTitle="Media" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total Number of Video on the platform" position="top">
          <statistics-card-line icon="VideoIcon" :statistic="data.music_count" statisticTitle="Videos" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total Number of Music on the platform" position="top">
          <statistics-card-line icon="MusicIcon" :statistic="data.music_count" statisticTitle="Music" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>


      <!-- Need more analysis on it -->
      <!-- <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total Number of Hours Watched On the Platform for the chosen interval" position="top">
          <statistics-card-line icon="ClockIcon" :statistic="data.watched_hours" statisticTitle="Total Hours Watched" type="area"></statistics-card-line>
        </vx-tooltip>
      </div> -->

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total Number of Media available" position="top">
          <statistics-card-line icon="ClockIcon" :statistic="data.watched_hours" statisticTitle="Total Available Media" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total number of Views on various Media(Video/Music) for the chosen interval" position="top">
          <statistics-card-line icon="PlayIcon" :statistic="data.view_percentage" statisticTitle="Views" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total number of Likes on various Media(Video/Music) for the chosen interval" position="top">
          <statistics-card-line icon="ThumbsUpIcon" :statistic="data.like_percentage" statisticTitle="Likes" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total number of Dislikes on various Media(Video/Music) for the chosen interval" position="top">
          <statistics-card-line icon="ThumbsDownIcon" :statistic="data.dislike_percentage" statisticTitle="Dislikes" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="How Many Videos or Music Content were added to User Favorites list" position="top">
          <statistics-card-line icon="HeartIcon" :statistic="data.favorite_percentage" statisticTitle="Favorites" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>




    </vs-row>

  </div>
</template>

<script>
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";

export default {
  name: 'ContentCards',
  props: [
      'data'
  ],
  data(){
    return {

    }
  },
  components: {
    StatisticsCardLine,
  },
}
</script>