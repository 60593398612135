<template>
  <apexchart type="treemap" height="400" :options="data.chartOptions" :series="data.series"></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: 'ContentGraph7',
  props: ['data'],
  components: {
    apexchart: VueApexCharts,
  },
  data(){
    return {

    }
  }
}
</script>