<template>
  <div>

    <vs-row>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total number of Cafe Tables held" position="top">
          <statistics-card-line icon="UsersIcon" :statistic="data.total_cafe_tables" statisticTitle="Cafe Tables" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Participation in Cafe Tables on Average" position="top">
          <statistics-card-line icon="CheckCircleIcon" :statistic="data.cafe_participation_pct ||0" statisticTitle="Cafe Table Participation (%)" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Average Cafe Length in Minutes" position="top">
          <statistics-card-line icon="CheckCircleIcon" :statistic="data.average_cafe_length ||0" statisticTitle="Average Cafe Length" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Cafe Rating on Average" position="top">
          <statistics-card-line icon="VideoIcon" :statistic="data.average_cafe_rating_pct || '-'" statisticTitle="Average Cafe Rating (%)" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total clicks on Confidant contact" position="top">
          <statistics-card-line icon="BoxIcon" :statistic="data.total_confidant_clicks" statisticTitle="Confidant clicks" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total Confidant Chat based Tickets" position="top">
          <statistics-card-line icon="CoffeeIcon" :statistic="data.total_confidant_tickets_chat" statisticTitle="Confidant Chats" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total Confidant Call based Tickets" position="top">
          <statistics-card-line icon="CalendarIcon" :statistic="data.total_confidant_tickets_call" statisticTitle="Confidant Calls" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

<!--       <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total Hits on Offers" position="top">
          <statistics-card-line icon="MessageCircleIcon" :statistic="data.offers_view_more_count" statisticTitle="Offer Hits" type="area"></statistics-card-line>
        </vx-tooltip>
      </div> -->


    </vs-row>

  </div>
</template>

<script>
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";

export default {
  name: 'OtherCards',
  props: [
      'data'
  ],
  data(){
    return {

    }
  },
  components: {
    StatisticsCardLine,
  },
}
</script>