<template>
  <div>

    <vs-row>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="How many employees were imported in the system" position="top">
          <statistics-card-line icon="UsersIcon" :statistic="data.total_count" statisticTitle="All Employees" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Employees who downloaded and registered themselves on iSabel app" position="top">
          <statistics-card-line icon="CheckCircleIcon" :statistic="data.registered_count" statisticTitle="Registered Employees" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="What percentage of employees engaged with content (Video/Music) on the platform" position="top">
          <statistics-card-line icon="CheckCircleIcon" :statistic="data.active_percent" statisticTitle="Active Users (%)" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total Number of Times Employee Viewed Videos" position="top">
          <statistics-card-line icon="VideoIcon" :statistic="data.video_interaction_count" statisticTitle="Total Video Interactions" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Percentage of employees who joined conversations hosted in the café" position="top">
          <statistics-card-line icon="CoffeeIcon" :statistic="data.cafe_participation_percent" statisticTitle="Café Interactions (%)" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Percentage of registered users who joined events hosted" position="top">
          <statistics-card-line icon="ActivityIcon" :statistic="data.event_engagement_percent" statisticTitle="Event engagement %" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total number of events that were made available to registered users" position="top">
          <statistics-card-line icon="SmileIcon" :statistic="'350Hrs'" statisticTitle="Total Media Content" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-tooltip color="primary" text="Total Offers Available on the Platform" position="top">
          <statistics-card-line icon="GiftIcon" :statistic="35" statisticTitle="Total Offers" type="area"></statistics-card-line>
        </vx-tooltip>
      </div>


    </vs-row>

  </div>
</template>

<script>
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";

export default {
  name: 'EmployeeCards',
  props: [
      'data'
  ],
  data(){
    return {

    }
  },
  components: {
    StatisticsCardLine,
  },
}
</script>