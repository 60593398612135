<template lang="html">
  <div>
    <vs-table :data="list">
      <template slot="header">
        <h3>
          Event List
        </h3>
        <br>
        <br>
      </template>
      <template slot="thead">
        <vs-th>
          Name
        </vs-th>
        <vs-th>
          Date
        </vs-th>
        <vs-th>
          #Users
        </vs-th>
        <vs-th>
          Event length (Mins.)
        </vs-th>
        <vs-th>
          Rating
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          <vs-td :data="data[indextr].title">
            {{data[indextr].title}}
          </vs-td>

          <vs-td :data="data[indextr].event_time">
            {{ moment(data[indextr].event_time).format('DD/MM/YYYY') }}
          </vs-td>

          <vs-td :data="data[indextr].no_users">
            {{data[indextr].attendance}}
          </vs-td>

          <vs-td :data="data[indextr].favorites">
            {{data[indextr].event_length}}
          </vs-td>

          <vs-td :data="data[indextr].favorites">
            {{ data[indextr].avg_rating?data[indextr].avg_rating:'-' }}
          </vs-td>

        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moment from 'moment-timezone' 
import Vue from 'vue'

Vue.prototype.moment = moment

export default {
  name: 'EventGraph2',
  props:['list'],
  data(){
    return {

    }
  }
}
</script>