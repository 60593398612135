<template lang="html">
  <div>
    <vs-table :data="data">
      <template slot="header">
        <h3>
          Most listened Music
        </h3>
        <br>
        <br>
      </template>
      <template slot="thead">
        <vs-th>
          Name
        </vs-th>
        <vs-th>
          Views
        </vs-th>
        <vs-th>
          Likes
        </vs-th>
        <vs-th>
          Dislikes
        </vs-th>
        <vs-th>
          Favourite
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          <vs-td :data="data[indextr].title">
            {{data[indextr].title}}
          </vs-td>

          <vs-td :data="data[indextr].views">
            {{data[indextr].views}}
          </vs-td>

          <vs-td :data="data[indextr].likes">
            {{data[indextr].likes}}
          </vs-td>

          <vs-td :data="data[indextr].dislikes">
            {{data[indextr].dislikes}}
          </vs-td>

          <vs-td :data="data[indextr].favorites">
            {{data[indextr].favorites}}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  name: 'ContentGraph6',
  props:['data']
}
</script>