<template>
  <vue-word-cloud
      style=" height: 300px; width: 80%; "
      :words="data"
      :color="getColor"
      font-family="Roboto"
  />
</template>

<script>
import VueWordCloud from 'vuewordcloud';

export default {
  name: 'ContentGraph4',
  props: ['data'],
  components: {
    [VueWordCloud.name]: VueWordCloud,
  },
  methods:{
    getColor(a,b,c){

      if(a[1]>150){
        return 'magenta'
      }
      if(a[1]>100){
        return 'purple'
      }
      if(a[1]>75){
        return 'red'
      }
      if(a[1]>50){
        return 'orange'
      }
      if(a[1]>25){
        return 'yello'
      }
      if(a[1]>10){
        return 'grey'
      }

      //([, weight]) => weight > 10 ? 'Green' : weight > 10 ? 'RoyalBlue' : 'Indigo'
    }
  }
}
</script>